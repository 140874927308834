/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url("/roboto/KFOlCnqEu92Fr1MmSU5fCRc4AMP6lbBP.woff2") format("woff2");
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url("/roboto/KFOlCnqEu92Fr1MmSU5fABc4AMP6lbBP.woff2") format("woff2");
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url("/roboto/KFOlCnqEu92Fr1MmSU5fCBc4AMP6lbBP.woff2") format("woff2");
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url("/roboto/KFOlCnqEu92Fr1MmSU5fBxc4AMP6lbBP.woff2") format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url("/roboto/KFOlCnqEu92Fr1MmSU5fCxc4AMP6lbBP.woff2") format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url("/roboto/KFOlCnqEu92Fr1MmSU5fChc4AMP6lbBP.woff2") format("woff2");
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url("/roboto/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2") format("woff2");
}
/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url("/roboto/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2") format("woff2");
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url("/roboto/KFOmCnqEu92Fr1Mu5mxKKTU1Kvnz.woff2") format("woff2");
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url("/roboto/KFOmCnqEu92Fr1Mu7mxKKTU1Kvnz.woff2") format("woff2");
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url("/roboto/KFOmCnqEu92Fr1Mu4WxKKTU1Kvnz.woff2") format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url("/roboto/KFOmCnqEu92Fr1Mu7WxKKTU1Kvnz.woff2") format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url("/roboto/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2") format("woff2");
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url("/roboto/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2") format("woff2");
}
/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("/roboto/KFOlCnqEu92Fr1MmEU9fCRc4AMP6lbBP.woff2") format("woff2");
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("/roboto/KFOlCnqEu92Fr1MmEU9fABc4AMP6lbBP.woff2") format("woff2");
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("/roboto/KFOlCnqEu92Fr1MmEU9fCBc4AMP6lbBP.woff2") format("woff2");
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("/roboto/KFOlCnqEu92Fr1MmEU9fBxc4AMP6lbBP.woff2") format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("/roboto/KFOlCnqEu92Fr1MmEU9fCxc4AMP6lbBP.woff2") format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("/roboto/KFOlCnqEu92Fr1MmEU9fChc4AMP6lbBP.woff2") format("woff2");
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("/roboto/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2") format("woff2");
}

/* cyrillic-ext */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto Mono"), local("RobotoMono-Regular"),
    url("/robotomono/L0x5DF4xlVMF-BfR8bXMIjhGq3-cXbKDO1w.woff2") format("woff2");
}
/* cyrillic */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto Mono"), local("RobotoMono-Regular"),
    url("/robotomono/L0x5DF4xlVMF-BfR8bXMIjhPq3-cXbKDO1w.woff2") format("woff2");
}
/* greek-ext */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto Mono"), local("RobotoMono-Regular"),
    url("/robotomono/L0x5DF4xlVMF-BfR8bXMIjhHq3-cXbKDO1w.woff2") format("woff2");
}
/* greek */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto Mono"), local("RobotoMono-Regular"),
    url("/robotomono/L0x5DF4xlVMF-BfR8bXMIjhIq3-cXbKDO1w.woff2") format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto Mono"), local("RobotoMono-Regular"),
    url("/robotomono/L0x5DF4xlVMF-BfR8bXMIjhEq3-cXbKDO1w.woff2") format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto Mono"), local("RobotoMono-Regular"),
    url("/robotomono/L0x5DF4xlVMF-BfR8bXMIjhFq3-cXbKDO1w.woff2") format("woff2");
}
/* latin */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto Mono"), local("RobotoMono-Regular"),
    url("/robotomono/L0x5DF4xlVMF-BfR8bXMIjhLq3-cXbKD.woff2") format("woff2");
}

@font-face {
  font-family: "text-security-disc";
  src: url("/textsecurity/text-security-disc.eot");
  src: url("/textsecurity/text-security-disc.eot?#iefix")
      format("embedded-opentype"),
    url("/textsecurity/text-security-disc.woff") format("woff"),
    url("/textsecurity/text-security-disc.ttf") format("truetype"),
    url("/textsecurity/text-security-disc.svg#text-security") format("svg");
}

html{
  height: 100%;
}


body {
  margin: 0;
  height: 100%;
  min-height: 100%;
  padding: 0;
  width: 100%;
  font-family: Roboto, sans-serif !important;
  overflow-y: scroll
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}


.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #f44336 !important;
}

.MuiAutocomplete-listbox {
  /* position: absolute; */
  top: 0;
  /* width: 100%; */
  padding: 0 !important;
  background: #fff;
  /* border: 1px solid #799BC1; */
}
.MuiAutocomplete-option {
  padding: 0 15px !important;
  font-size: 16px;
  min-height: 40px !important;
}
.MuiStepConnector-alternativeLabel {
  left: calc(-50% + 11px) !important;
  right: calc(50% + 11px) !important;
}
.MuiStepper-root {
  padding: 20px !important;
}
.MuiStep-alternativeLabel {
  height: 22px !important;
}
.MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel.MuiPaper-elevation0{
  width: 100% !important;
  padding: 0px !important;
}

.MuiPickersBasePicker-pickerView {
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
}

.MuiDialogActions-root {
  justify-content: center !important;
}

.MuiPickersDay-daySelected:hover {
  background-color: #4cc5f2 !important;
}

.MuiPickersDay-daySelected {
  background-color: #4cc5f2 !important;
}

.MuiAutocomplete-listbox {
  border: 1px solid transparent !important;
  border-radius: 2px;
  z-index: 1 !important;
  box-shadow: 1px 1px 3px #00000052;
}

.MuiAutocomplete-popper {
  z-index: 1 !important;
  margin-top: 10px;
}

button {
  font: 400 13.3333px Roboto, sans-serif;
  font-family: Roboto, sans-serif !important;
}

.mr-15 {
  margin-right: 15px;
  margin-left: -15px;
}


.custom-option-autocomplete {
  margin-top: -20px;
  width: calc(100% + 15px);
  margin-left: -15px;
  padding-left: 15px;
  padding-bottom: 20px;
  color: #7e7c7c;
}

.direction-button-link {
  font-size: 14px;
  color: #00C8FF
}

.direction-button-link-bold {
  font-size: 14px;
  color: #00C8FF;
  font-weight: bold;
  cursor: pointer;
}

.MuiAutocomplete-popper  svg {
 opacity: 0.5;
 min-height: 20px;
 min-width: 20px;
}



@media (min-width: 1000px) {
  .slashedPopUp{
    height: calc(100% - 14%) !important;
    top: 7% !important;
    bottom: 7%;
  }
}

.mini-message .errorWrapper{
  margin: 0 10px 0px 10px !important;
}

.view-over{
  z-index: 400 !important;
}

.mini-message{
  border-bottom-width : 4px !important;
}

.ni-toast .errorWrapper > div:first-child{
  width: auto;
  height: auto;
}

.MuiAutocomplete-popper{
  z-index: 199 !important;
}
