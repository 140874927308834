.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.MuiExpansionPanel-rounded > .MuiSvgIcon-root {
  color: aqua;
}

.MuiInputAdornment-positionEnd {
  margin-right: 8px;
  position: absolute;
  right: 0;
  color: #4cc5f2
}

.MuiInputAdornment-positionStart {
  color: #4cc5f2
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  background: white;
}

.alert-enter {
  left: -200px;
  
}
.alert-enter-active {
  left: 0px;
  transition: left 300ms;
}
.alert-exit {
  left: 0px;
  opacity: 0;
}
.alert-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
.MuiTab-textColorPrimary.Mui-selected {
  color: #00C8FF !important;
}

.MuiTabs-indicator {
  background-color: #00C8FF !important;
}

.MuiTabs-root {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.12);
}

.MuiCollapse-container {
  border-top: 1px solid #eee;
}

select + svg {
  color: #4cc5f2 !important;
}

.textGrey {
  color: #565656;
  line-height: 21px;
  margin-top: 30px;
}

.textTeal {
  color: #00C8FF;
  line-height: 21px;
}

.textContract {
  color: #00377D;
  margin-top: 10px;
  font-size: 18px;
}
.textWhite {
  color: #FFFFFF;
}
.externalURL {
  text-decoration: none;
  color: #00C8FF;
}

.promotion-bundle {
    display: flex;
    width: 30px;
    justify-content: space-between;
}

.progress-status {
  font-size: 14px;
  color: #FFFFFF
}

.layout-msg-link {
  color: #00C8FF;
  cursor: pointer;
  text-decoration: #00C8FF;
  text-decoration-line: underline;
}

.MuiTabs-flexContainer {
  background: #FFFFFF;
}


.MuiFormControl-root:hover .MuiInputLabel-outlined{
  color: #555555 !important;
  border-width: 1px !important;
}
.MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline{
  border-color: #555555 !important;
  border-width: 1px !important;
}

.MuiFormLabel-filled,.MuiFormLabel-filled ~ div fieldset,
.MuiFormLabel-filled ~ div input.MuiOutlinedInput-input, 
.MuiFormLabel-filled ~ div textarea.MuiOutlinedInput-inputMultiline, 
.MuiFormLabel-filled ~ div select.MuiOutlinedInput-input,
.MuiFormLabel-filled ~ div select.MuiOutlinedInput-inputSelect{
  border-color: #555555 !important;
  color:  #555555;
}
.Mui-focused ~ div select.MuiOutlinedInput-input,
.Mui-focused ~ div select.MuiOutlinedInput-inputSelect,
.Mui-focused ~ div input.MuiOutlinedInput-input, 
.Mui-focused ~ div textarea.MuiOutlinedInput-inputMultiline,.selectKey{
  color:  #555555 !important; 
}

.MuiOutlinedInput-notchedOutline {
  color: #555555;
  border-color: #555555 !important;
}
.MuiFormHelperText-root {
  color: #8E8E8E;
}

.MuiFormLabel-root {
  color: #555555;
}

.MuiFormLabel-root.Mui-disabled {
  color: #555555;
}
.MuiFormLabel-root.Mui-focused {
  color: #555555;
}

.MuiAutocomplete-endAdornment > .MuiButtonBase-root  > .MuiIconButton-label > .MuiSvgIcon-root {
    color: #00C8FF;
}

button{
   box-shadow: none !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}